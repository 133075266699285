/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type useDeputadosQueryVariables = {};
export type useDeputadosQueryResponse = {
    readonly deputados: {
        readonly nodes: ReadonlyArray<{
            readonly id: string;
            readonly nomeCompleto: string;
            readonly urlFoto: string;
        } | null>;
    } | null;
};
export type useDeputadosQuery = {
    readonly response: useDeputadosQueryResponse;
    readonly variables: useDeputadosQueryVariables;
};



/*
query useDeputadosQuery {
  deputados {
    nodes {
      id
      nomeCompleto
      urlFoto
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "DeputadosConnection",
    "kind": "LinkedField",
    "name": "deputados",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Deputado",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nomeCompleto",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "urlFoto",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useDeputadosQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useDeputadosQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "00f4b51abbcb89df29c700a0fb7ca69c",
    "id": null,
    "metadata": {},
    "name": "useDeputadosQuery",
    "operationKind": "query",
    "text": "query useDeputadosQuery {\n  deputados {\n    nodes {\n      id\n      nomeCompleto\n      urlFoto\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '1e0f636a5e7e7b773ce8b2461a38cd9a';
export default node;
