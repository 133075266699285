import { makeStyles } from "@material-ui/core"

export const useDeputadoStyles = makeStyles((theme) => ({
  deputado: {
    cursor: "pointer",

    padding: 5,
    borderRadius: 99,

    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.primary.light,
  },
  deputadoAvatar: { width: 100, height: 100 },
  deputadoName: {
    flex: 1,
    color: "white",
    marginLeft: ".1em",
    textAlign: "center",
  },
}))
