import { useState, useEffect } from "react"
import { useQuery, graphql } from "relay-hooks"
import { useDeputadosQueryResponse } from "./__generated__/useDeputadosQuery.graphql"

type NotNull<T> = T extends null ? never : T

export type Deputado = NotNull<
  useDeputadosQueryResponse["deputados"]
>["nodes"][any]

export function useDeputados() {
  const [deputados, setDeputados] = useState<
    useDeputadosQueryResponse["deputados"]
  >()
  const query = useQuery<{
    variables: {}
    response: useDeputadosQueryResponse
  }>(graphql`
    query useDeputadosQuery {
      deputados {
        nodes {
          id
          nomeCompleto
          urlFoto
        }
      }
    }
  `)
  useEffect(() => {
    if (!query.isLoading && query.data?.deputados) {
      setDeputados(query.data?.deputados)
    }
  }, [query.isLoading, query.data])
  return deputados?.nodes || []
}
