import { ActionType } from "../Reducer"

export const openMenu: ActionType = {
  callback: (state) => ({
    ...state,
    menuOpen: true,
  }),
}

export const closeMenu: ActionType = {
  callback: (state) => ({
    ...state,
    menuOpen: false,
  }),
}
