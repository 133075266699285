import "./index.css"

import { ThemeProvider } from "@material-ui/core"
import React from "react"
import ReactDOM from "react-dom"
import { RelayEnvironmentProvider } from "relay-hooks"

import environment from "./api/relayEnvironment"
import Router from "./core/Router"
import reportWebVitals from "./reportWebVitals"
import { applicationTheme } from "./core/theme"
import Reducer from "./core/Reducer/Reducer"

ReactDOM.render(
  <React.StrictMode>
    <RelayEnvironmentProvider environment={environment}>
      <ThemeProvider theme={applicationTheme}>
        <Reducer>
          <Router />
        </Reducer>
      </ThemeProvider>
    </RelayEnvironmentProvider>
  </React.StrictMode>,
  document.getElementById("root"),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
