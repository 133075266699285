import { makeStyles } from "@material-ui/core"

export const useHomepageStyles = makeStyles({
  center: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
})
