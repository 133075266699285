import { makeStyles } from "@material-ui/core"
import { applicationTheme } from "../../core/theme"

export const drawerWidth = 240

export const useDrawerStyles = makeStyles({
  root: {
    width: drawerWidth,
  },
  paper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: applicationTheme.spacing(0, 1),
    // necessary for content to be below app bar
    ...applicationTheme.mixins.toolbar,
    justifyContent: "flex-end",
  },
})
