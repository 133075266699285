export default function properName(name: string = "") {
  return name
    .split(" ")
    .map((namePart) => {
      const firstCharacter = namePart[0]
      const remainder = namePart.substring(1)
      return `${firstCharacter}${remainder.toLowerCase()}`
    })
    .join(" ")
}
