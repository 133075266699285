import { mapValues } from "lodash"
import React, { createContext, useContext, useReducer, ReactChild } from "react"

import actions from "./actions"

const storageKey = "informate.pt"

export type ActionType<Args = any> = {
  args?: Args
  callback: (state: ApplicationState, args?: Args) => ApplicationState
}

export type ApplicationState = {
  menuOpen: boolean
}

const defaultState: ApplicationState = { menuOpen: false }

export type ReducerContextType<
  Actions extends { [key: string]: ActionType }
> = {
  actions?: {
    [key in keyof Actions]: (
      args?: Parameters<Actions[key]["callback"]>[1],
    ) => void
  }
  state: ApplicationState
}

const ReducerContext = createContext<ReducerContextType<typeof actions>>({
  state: defaultState,
})

function bindActions(dispatch: any) {
  return mapValues(actions, (_, key) => (args: any) =>
    dispatch({ type: key, args }),
  )
}

function loadFromStorage(): ApplicationState {
  try {
    const storedStateString = localStorage.getItem(storageKey)
    if (storedStateString) {
      return JSON.parse(storedStateString)
    } else {
      return defaultState
    }
  } catch (e) {
    return defaultState
  }
}
function saveToStorage(state: object) {
  localStorage.setItem(storageKey, JSON.stringify(state))
}

function reducer(
  state: ApplicationState,
  action: { type: keyof typeof actions; args: any },
) {
  const newState = actions[action.type].callback(state, action.args || {})
  saveToStorage(newState)
  return newState
}

interface ReducerProps {
  children: ReactChild
}

export default function Reducer({ children }: ReducerProps) {
  const [state, dispatch] = useReducer(reducer, loadFromStorage())

  return (
    <ReducerContext.Provider value={{ actions: bindActions(dispatch), state }}>
      {children}
    </ReducerContext.Provider>
  )
}

export function useApplicationReducer() {
  return useContext<ReducerContextType<typeof actions>>(ReducerContext)
}
