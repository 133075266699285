import { makeStyles } from "@material-ui/core"
import { applicationTheme } from "../../core/theme"
import { drawerWidth } from "../Drawer/Drawer.styles"

export const useAppBarStyles = makeStyles<
  typeof applicationTheme,
  { menuOpen: boolean }
>({
  appBar: ({ menuOpen }) =>
    menuOpen
      ? {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: drawerWidth,
          transition: applicationTheme.transitions.create(["margin", "width"], {
            easing: applicationTheme.transitions.easing.easeOut,
            duration: applicationTheme.transitions.duration.enteringScreen,
          }),
        }
      : {
          transition: applicationTheme.transitions.create(["margin", "width"], {
            easing: applicationTheme.transitions.easing.sharp,
            duration: applicationTheme.transitions.duration.leavingScreen,
          }),
        },
})
