import React from "react"
import { BrowserRouter, Switch } from "react-router-dom"

import Deputados from "../../screens/Deputados"
import Homepage from "../../screens/Homepage"
import Wrapper from "../Wrapper/Wrapper"

export default function Router() {
  return (
    <BrowserRouter>
      <Wrapper>
        <Switch>
          {Deputados}
          {Homepage}
        </Switch>
      </Wrapper>
    </BrowserRouter>
  )
}
