import React from "react"
import { Route } from "react-router"
import { Typography } from "@material-ui/core"
import { useHomepageStyles } from "./Homepage.styles"

function Homepage() {
  const styles = useHomepageStyles()
  return (
    <div className={styles.center}>
      <Typography variant="h4">Bem vindo.</Typography>
    </div>
  )
}

export default (
  <Route path="/" exact>
    <Homepage />
  </Route>
)
