import { makeStyles } from "@material-ui/core"

export const useDeputadosStyles = makeStyles({
  title: {
    padding: "0.3em 0",
  },
  deputados: {
    flex: 1,
    padding: "1em",

    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
})
