import { ReactChild } from "react"
import React from "react"
import AppBar from "../../components/AppBar"
import Drawer from "../../components/Drawer"

interface WrapperProps {
  children: ReactChild
}

export default function Wrapper({ children }: WrapperProps) {
  return (
    <>
      <AppBar />
      <Drawer />
      <div style={{ marginTop: 60 }} />
      {children}
    </>
  )
}
