import {
  AppBar as MUIAppBar,
  Toolbar,
  Typography,
  IconButton,
} from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import React from "react"
import { useApplicationReducer } from "../../core/Reducer/Reducer"
import { useAppBarStyles } from "./AppBar.styles"

export default function AppBar() {
  const { state, actions } = useApplicationReducer()

  const styles = useAppBarStyles({ menuOpen: state.menuOpen })
  return (
    <MUIAppBar className={styles.appBar} position="fixed" title="Informa-te.">
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={state.menuOpen ? actions?.closeMenu : actions?.openMenu}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h4">Informa-te.</Typography>
      </Toolbar>
    </MUIAppBar>
  )
}
