import { Avatar, Grid, Typography } from "@material-ui/core"
import React from "react"

import { Deputado as DeputadoT } from "../../api/useDeputados"
import properName from "../../utils/properName"
import { useDeputadoStyles } from "./Deputado.styles"

export default function Deputado({ deputado }: { deputado: DeputadoT }) {
  const styles = useDeputadoStyles()
  const avatarUri = deputado?.urlFoto
  return (
    <Grid item xs={12} sm={6} lg={4} xl={3}>
      <div
        className={styles.deputado}
        onClick={() => window.open(avatarUri, "_blank")}
      >
        <Avatar className={styles.deputadoAvatar} src={avatarUri} />
        <div className={styles.deputadoName}>
          <Typography variant="h6">
            {properName(deputado?.nomeCompleto)}
          </Typography>
        </div>
      </div>
    </Grid>
  )
}
