import React from "react"
import { Route } from "react-router"
import { Typography, Grid, Divider } from "@material-ui/core"
import { useDeputadosStyles } from "./Deputados.styles"
import { useDeputados } from "../../api/useDeputados"
import Deputado from "../../components/Deputado"

function Deputados() {
  const styles = useDeputadosStyles()
  const deputados = useDeputados()
  return (
    <div className={styles.deputados}>
      <Typography className={styles.title} variant="h4">
        Deputados
      </Typography>
      <Grid spacing={2} container>
        {deputados.map((deputado, index) => (
          <Deputado key={index} deputado={deputado} />
        ))}
      </Grid>
    </div>
  )
}

export default (
  <Route path="/deputados" exact>
    <Deputados />
  </Route>
)
