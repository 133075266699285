import {
  Drawer as MUIDrawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core"
import React from "react"
import { useApplicationReducer } from "../../core/Reducer/Reducer"
import { useDrawerStyles } from "./Drawer.styles"

import PersonIcon from "@material-ui/icons/Person"
import HouseIcon from "@material-ui/icons/House"
import { Link } from "react-router-dom"

export default function Drawer() {
  const {
    state: { menuOpen },
    actions,
  } = useApplicationReducer()

  const styles = useDrawerStyles()

  return (
    <MUIDrawer
      onClose={actions?.closeMenu}
      open={menuOpen}
      classes={{ root: styles.root, paper: styles.paper }}
    >
      {/* <div className={styles.drawerHeader} /> */}
      <List>
        <Link onClick={actions?.closeMenu} to="/">
          <ListItem button>
            <ListItemIcon>
              <HouseIcon />
            </ListItemIcon>
            <ListItemText primary="Página Inicial" />
          </ListItem>
        </Link>
        <Divider />
        <Link onClick={actions?.closeMenu} to="/deputados">
          <ListItem button>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="Deputados" />
          </ListItem>
        </Link>
        <Divider />
      </List>
    </MUIDrawer>
  )
}
